.post {
  margin-bottom: $line-height * 3;

  img {
    margin: auto;
  }
}

.post-title {
  @extend .mt-0;
}

.post-link {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-link;
  }
}

.post-meta {
  margin-top: -$line-height;
  font-size: $font-size / $type-scale;
}

.post-read-more {
  font-size: $font-size / $type-scale;
  font-style: italic;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: top;
}
