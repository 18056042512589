.footer {
  color: $color-text;
  font-size: $font-size / $type-scale / $type-scale;
  font-style: italic;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: top;
  padding: ($line-height * 2) 0;
}
